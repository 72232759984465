import './index.scss';
import DOMPurify from 'dompurify';
export default CoverLetter; function CoverLetter({ Template, FAQUrl }) {
    const pageBreak = Template.split('#PAGE_BREAK:');
    const index = pageBreak.findIndex(item => item.includes('COVER LETTER#')) - 1; 

    let template = pageBreak[index].replace(pageBreak[index].split('#')[0].trim() + '#', '');
    if(template.includes('</head>')) {
        template = template.split('</head>')[1];
    }
    const coverLetter = DOMPurify.sanitize('<html>' + template + '</html>');
    
    return (
        <>
            <div id="stepper-cover-letter-div">
                <div className='row stepper-title'>
                    <p>
                        Cover Letter
                    </p>
                </div>
                <div className='row stepper-p cover-letter-content'>
                    <div dangerouslySetInnerHTML={{ __html: coverLetter }} />
                    <a id='faq-button' className='faq-url' href={FAQUrl} target='_blank' rel="noopener noreferrer">VIEW FAQ</a>
                </div>
            </div>
        </>
    );
}