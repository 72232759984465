import './index.scss';
import DOMPurify from 'dompurify';
export default ExitOfferReturns; function ExitOfferReturns({ Template }) {
    const pageBreak = Template.split('#PAGE_BREAK:');
    const index = pageBreak.findIndex(item => item.includes('EXIT OFFER RETURNS#')) - 1;
    
    let template = pageBreak[index].replace(pageBreak[index].split('#')[0].trim() + '#', '');
    if(template.includes("</head>")) {
        template = template.split('</head>')[1];
    }

    const exitOfferReturns = DOMPurify.sanitize('<html>' + template + '</html>'); //Default index is 1
    return (
        <>
            <div id="stepper-exit-offer-returns-div">
                <div className='row stepper-title'>
                    <p> 
                        Exit offer returns
                    </p>
                </div>
                <div className='row stepper-p exit-offer-returns-content'>
                    <div dangerouslySetInnerHTML={{ __html: exitOfferReturns }} />
                </div>
            </div>
        </>
    );
}