import './index.scss';
import { useState, useEffect } from 'react'
import { get } from '../../../util/request';
import { userInfo } from '../../../redux/slice';
import { useSelector } from 'react-redux';

export default LandAndLoanInvestments; function LandAndLoanInvestments() {
    const user = useSelector(userInfo);
    const [landAndLoanInvestments, setLandAndLoanInvestments] = useState([]);

    const getLandAndLoanInvestments = async () => {
        let params = {
            accessToken: user.accessToken,
            userId: user.userId,
            clientId: user.clientID
        }

        const data = await get('home/landAndLoanInvestments', params);
        setLandAndLoanInvestments(data);
    }

    const formatNumber = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value);

    useEffect(() => {
        getLandAndLoanInvestments();
    }, []);

    return (<>
        <div className='land-and-loan-investments'>
            <div className="land-investment row">
                <p className='left-border home-title'>
                    Land Investment
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Region & Currency</th>
                            <th>Total Original Investment</th>
                            <th>Total Active Amount</th>
                            <th>Total Net Exit Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            landAndLoanInvestments.filter(x => x.productType.toUpperCase() !== "LOAN" && x.currencyCode === 'USD').length > 0 ? (<>
                                {
                                    landAndLoanInvestments.filter(x => x.productType.toUpperCase() !== "LOAN" && x.currencyCode === 'USD').map((land) => (<>
                                        <tr>
                                            <td>{land.assetRegion} ({land.currencyCode})</td>
                                            <td>{formatNumber(land.totalOriginalInvestment)}</td>
                                            <td>{formatNumber(land.totalActiveAmount)}</td>
                                            <td>{formatNumber(land.totalNetExitAmount)}</td>
                                        </tr>
                                    </>))
                                }
                            </>) : (<>
                                <tr>
                                    <td>United States (USD)</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </>)
                        }
                        {
                            landAndLoanInvestments.filter(x => x.productType.toUpperCase() !== "LOAN" && x.currencyCode === 'CAD').length > 0 ? (<>
                                {
                                    landAndLoanInvestments.filter(x => x.productType.toUpperCase() !== "LOAN" && x.currencyCode === 'CAD').map((land) => (<>
                                        <tr>
                                            <td>{land.assetRegion} ({land.currencyCode})</td>
                                            <td>{formatNumber(land.totalOriginalInvestment)}</td>
                                            <td>{formatNumber(land.totalActiveAmount)}</td>
                                            <td>{formatNumber(land.totalNetExitAmount)}</td>
                                        </tr>
                                    </>))
                                }
                            </>) : (<>
                                <tr>
                                    <td>Canada (CAD)</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </>)
                        }


                    </tbody>
                </table>
            </div>
            <div className="loan-investment row">
                <p className='left-border home-title'>
                    Loan Investment
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Currency</th>
                            <th>Total Original Principal</th>
                            <th>Total Outstanding Balance</th>
                            <th>Total Principal Repayment</th>
                            <th>Total Interest Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            landAndLoanInvestments.filter(x => x.productType.toUpperCase() === "LOAN" && x.currencyCode === 'USD').length > 0 ? (<>
                                {landAndLoanInvestments.filter(x => x.productType.toUpperCase() === "LOAN" && x.currencyCode === 'USD').map((land) => (<>
                                    <tr>
                                        <td>{land.assetRegion} ({land.currencyCode})</td>
                                        <td>{formatNumber(land.totalOriginalPrincipal)}</td>
                                        <td>{formatNumber(land.totalOutstandingBalance)}</td>
                                        <td>{formatNumber(land.totalPrincipalRepayment)}</td>
                                        <td>{formatNumber(land.totalInterestPayment)}</td>
                                    </tr>
                                </>))}
                            </>) : (<>
                                <tr>
                                    <td>United States (USD)</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </>)
                        }

                        {
                            landAndLoanInvestments.filter(x => x.productType.toUpperCase() === "LOAN" && x.currencyCode === 'CAD').length > 0 ? (<>
                                {
                                    landAndLoanInvestments.filter(x => x.productType.toUpperCase() === "LOAN" && x.currencyCode === 'CAD').map((land) => (<>
                                        <tr>
                                            <td>{land.assetRegion} ({land.currencyCode})</td>
                                            <td>{formatNumber(land.totalOriginalPrincipal)}</td>
                                            <td>{formatNumber(land.totalOutstandingBalance)}</td>
                                            <td>{formatNumber(land.totalPrincipalRepayment)}</td>
                                            <td>{formatNumber(land.totalInterestPayment)}</td>
                                        </tr>
                                    </>))
                                }
                            </>) : (<>
                                <tr>
                                    <td>Canada (CAD)</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </>)
                        }


                    </tbody>
                </table>
            </div>
        </div>
    </>);
}