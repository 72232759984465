import './index.scss';
import { useEffect } from 'react';

export default ExitBallot; function ExitBallot({ Template, VotingResult, handleExitBallotSave, handleOfferLetterSave }) {
    useEffect(() => {
        const wrapper = document.getElementById('exit-ballot-div');
        let offerLetter = [];
        wrapper.addEventListener('click', (event) => {
            const node = event.target;
            if (node.nodeName === 'INPUT') {
                let result = [];
                [].forEach.call(wrapper.querySelectorAll('input'), function (element) {
                    if (element.checked) {
                        if (element.type === 'radio') {
                            let options = {
                                optionSequence: element.name.split('_')[2],
                                purchaserID: element.name.split('_')[0],
                            }

                            if (element.value.split('_')[1] === "Yes") {
                                options.yesVoteUnit = element.value.split('_')[2]; //Number();
                                options.noVoteUnit = 0;
                                options.isManual = false;
                                options.offer = element.value.split('_')[0]
                            }
                            else if (element.value.split('_')[1] === "No") {
                                options.noVoteUnit = element.value.split('_')[2]; //Number(element.value.split('_')[2]);
                                options.yesVoteUnit = 0;
                                options.isManual = false;
                                options.offer = element.value.split('_')[0]
                            }
                            else if (element.value.split('_')[1] === "Manual") {
                                options.noVoteUnit = 0;
                                options.yesVoteUnit = 0;
                                options.isManual = true;
                                options.offer = element.value.split('_')[0]
                            }
                            else {
                                options.noVoteUnit = 0;
                                options.yesVoteUnit = 0;
                                options.isManual = false;
                                options.offer = element.value.split('_')[0]
                            }
                            result.push(options);
                        }
                    }
                });

                if (result.length === (wrapper.querySelectorAll('input').length / 3)) {
                    handleExitBallotSave(result);
                }
            }
            else if (node.nodeName === 'A') {
                const isExist = offerLetter.includes(node.href)
                if (!isExist) {
                    handleOfferLetterSave((prevArray) => [...prevArray, node.href]);
                }
            }
            else {
                return;
            }
        });
    });

    useEffect(() => {
        if (Template !== '') {
            const wrapper = document.getElementById('exit-ballot-div');
            const radioButtons = wrapper.querySelectorAll('input');
            VotingResult.forEach((result) => {
                radioButtons.forEach((radio) => {
                    let answer = '';
                    let totalUnit = 0;
                    if (result.yesVoteUnit > 0 && result.noVoteUnit === 0) {
                        answer = 'Yes';
                        totalUnit = result.yesVoteUnit;
                    }
                    else if (result.yesVoteUnit === 0 && result.noVoteUnit > 0) {
                        answer = 'No'
                        totalUnit = result.noVoteUnit;
                    }
                    else {
                        answer = 'Manual'
                        totalUnit = result.totalUnit;
                    }

                    if (radio.value === (result.offer + '_' + answer + '_' + totalUnit)) {
                        radio.checked = true;
                    }
                });
            });
        }
    }, [Template, VotingResult]);


    const pageBreak = Template.split('#PAGE_BREAK:');
    const index = pageBreak.findIndex(item => item.includes('EXIT BALLOT#')) - 1;

    let template = pageBreak[index].replace(pageBreak[index].split('#')[0].trim() + '#', '');
    if (template.includes('</head>')) {
        template = template.split('</head>')[1];
    }
    const exitBallot = '<html>' + template + '</html>';
    return (
        <>
            <div id="exit-ballot-div">
                <div className='row stepper-title'>
                    <p>
                        Exit ballot
                    </p>
                </div>
                <div className='row stepper-p exit-ballot-content'>
                    <div dangerouslySetInnerHTML={{ __html: exitBallot }} />
                </div>
            </div>
        </>
    );
}