import { useEffect } from 'react';
import './index.scss';
import DOMPurify from 'dompurify';

export default KYC; function KYC({ Template, VotingDetails, handleUSResidentSave }) {
    
    const pageBreak = Template.split('#PAGE_BREAK:');
    const index = pageBreak.findIndex(item => item.includes('KYC#')) - 1; 

    let template = pageBreak[index].replace(pageBreak[index].split('#')[0].trim() + '#', '');
    if (template.includes('</head>')) {
        template = template.split('</head>')[1];
    }

    const kyc = DOMPurify.sanitize('<html>' + template + '</html>');
    useEffect(() => {
        const usTaxRes = document.getElementById('usTaxResNew');
        if (usTaxRes != null) {
            usTaxRes.value = VotingDetails.usTaxRes;
            usTaxRes.addEventListener('change', function () {
                handleUSResidentSave(usTaxRes.value);
            });
        }
    });



    return (
        <>
            <div id="kyc-div">
                <div className='row stepper-title'>
                    <p>
                        KYC
                    </p>
                </div>
                <div className='row stepper-p kyc-content'>
                    <div dangerouslySetInnerHTML={{ __html: kyc }} />
                </div>
            </div>
        </>
    );
}