import './index.scss';
import DOMPurify from 'dompurify';
import { useEffect } from 'react';
export default NFC; function NFC({ Template, VotingDetails, handleChangeStateSave, handleChangeUSCitizenSave }) {

    const pageBreak = Template.split('#PAGE_BREAK:');
    const index = pageBreak.findIndex(item => item.includes('NFC#')) - 1;

    let template = pageBreak[index].replace(pageBreak[index].split('#')[0].trim() + '#', '');
    if (template.includes('</head>')) {
        template = template.split('</head>')[1];
    }

    const nfc = DOMPurify.sanitize('<html>' + template + '</html>');
    useEffect(() => {
        const usResState = document.getElementById('usResState');
        const usRes = document.getElementById('usRes');
        const usCitizen = document.getElementById('citizen');

        if (usResState != null) {
            usResState.classList.add('form-control');

            usResState.addEventListener('change', function (event) {
                handleChangeStateSave(event.target.value);
            });
        }

        if (usCitizen != null) {
            usCitizen.addEventListener('click', function (event) {
                handleChangeUSCitizenSave(event.target.checked);
            });

            usCitizen.checked = VotingDetails.usCitizen;
        }

        if (VotingDetails.usTaxRes === 'US resident') {
            if (usRes != null) {
                usRes.checked = true;
            }
            if (usResState != null) {
                usResState.disabled = false;
                usResState.value = VotingDetails.usResState;
            }
        }
    });

    return (
        <>
            <div id="nfc-div">
                <div className='row stepper-title nfc-title'>
                    <p>
                        NFC
                    </p>
                </div>
                <div className='row stepper-p nfc-content'>
                    <div dangerouslySetInnerHTML={{ __html: nfc }} />
                </div>
            </div>
        </>
    );
}