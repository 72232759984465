import './index.scss'
import * as React from 'react';
import { Box, Stepper, Step, StepLabel, Button, StepIcon } from '@mui/material';
import { margin, styled } from '@mui/system';
import { get } from '../../../util/request';
import { userInfo } from '../../../redux/slice';
import { useSelector } from 'react-redux';
import CoverLetter from '../exitVotingStepper/coverLetter/index.js'
import ExitOfferReturns from '../exitVotingStepper/exitOfferReturns/index.js'
import ExitBallot from '../exitVotingStepper/exitBallot/index.js'
import KYC from '../exitVotingStepper/kyc/index.js'
import NFC from '../exitVotingStepper/nfc/index.js'
import ExitSummary from '../exitVotingStepper/exitSummary/index.js'
import Loading from '../../../components/common/loading.js'

export default ExitVotingStepper; function ExitVotingStepper(prop) {
  const [steps, setSteps] = React.useState([]);
  const [completedSteps, setCompletedSteps] = React.useState(new Set());
  const user = useSelector(userInfo);
  const [activeStep, setActiveStep] = React.useState(0);
  const [exitProcessHeader, setExitProcessHeader] = React.useState({});
  const [nextButtonStatus, setNextButtonStatus] = React.useState(true);
  const [votingResult, setVotingResult] = React.useState([]);
  const [offerLetter, setOfferLetter] = React.useState([]);
  const [votingDetails, setVotingDetails] = React.useState({ usTaxRes: '-', usRes: false, usCitizen: false, usResState: '-', recId: prop.RecId });
  const [exitTemplate, setExitTemplate] = React.useState('');

  const CustomStepIcon = styled(StepIcon)(({ theme }) => ({
    width: 37,
    height: 37,
    '& .MuiStepIcon-circle': {
      width: 37,
      height: 37,
      borderRadius: '50%'
    },
  }));

  const setup = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
      accountNum: user.clientID,
      headerRecId: prop.RecId
    }
    await get('exitVoting/exitProcessHeader', params).then((data) => {
      updateTemplate(data);
    });
  }

  const updateTemplate = async (exitProcessHeader) => {
    let updatedTemplate = '';
    updatedTemplate = exitProcessHeader.bodyEN;
    switch (exitProcessHeader.primaryLanguage) {
      case 'ZH_CN':
      case 'Simplified Chinese':
        updatedTemplate = exitProcessHeader.bodySC;
        break;
      case 'ZH_HK':
      case 'Traditional Chinese':
        updatedTemplate = exitProcessHeader.bodyTC;
        break;
      case 'JP':
      case 'Japanese':
        updatedTemplate = exitProcessHeader.bodyJA;
        break;
      default:
        updatedTemplate = exitProcessHeader.bodyEN;
        break;
    }
    setExitTemplate(updatedTemplate);
    let templateSteps = updatedTemplate.split('#PAGE_BREAK:');
    let newSteps = [];
    for (let i = 1; i < templateSteps.length; i++) {
      newSteps.push(templateSteps[i].split('#')[0].trim());
    };

    if (newSteps.includes('KYC')) {
      if (newSteps.includes('NFC')) {
        const [movedItem] = newSteps.splice(newSteps.indexOf('NFC'), 1);
        newSteps.splice(newSteps.indexOf('KYC') + 1, 0, movedItem);
      }
    }
    else {
      if (newSteps.includes('NFC')) {
        newSteps = newSteps.filter(item => item !== 'NFC');
      }
    }
    setSteps(newSteps);
    setExitProcessHeader(exitProcessHeader);
  }

  //#region Exit ballot funtion
  const handleExitBallot = (data) => {
    setVotingResult(data);
  };

  const handleOfferLetter = (data) => {
    setOfferLetter(data);
  };
  //endregion

  //#region NFC function
  const handleChangeState = (data) => {
    setVotingDetails(prevData => ({
      ...prevData,
      usResState: data
    }));
  };

  const handleChangeUSCitizen = (data) => {
    setVotingDetails(prevData => ({
      ...prevData,
      usCitizen: data
    }));
  }
  //#endregion

  //#region KYC function
  const handleUSResident = (data) => {
    setVotingDetails(prevData => ({
      ...prevData,
      usTaxRes: data,
      usRes: data === 'US resident' ? true : false,
      usResState: data === 'US resident' ? prevData.usResState : '-',
      usCitizen: data === 'US resident' ? prevData.usCitizen : false
    }));
  }
  //#endregion

  const handleNext = () => {
    if (activeStep < steps.length) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      const newCompletedSteps = new Set(completedSteps);
      newCompletedSteps.add(activeStep);
      setCompletedSteps(newCompletedSteps);

      if (activeStep === steps.indexOf('KYC')) {
        if (votingDetails.usTaxRes === 'Not US resident') {
          setActiveStep((prevActiveStep) => prevActiveStep + 2);
        }
        else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => {
    if (completedSteps.has(step)) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setActiveStep(step);
    }
  };

  React.useEffect(() => {
    setup();
  }, []);


  React.useEffect(() => {
    exitBallotNextCheck();
  }, [votingResult, offerLetter]);

  const exitBallotNextCheck = () => {
    if (activeStep === steps.indexOf('EXIT BALLOT')) { //Exit ballot
      const wrapper = document.getElementById('exit-ballot-div');
      if (wrapper !== null) {
        const offerCount = (wrapper.querySelectorAll('input').length / 3);
        const distinctOfferLetter = [...new Set(offerLetter)];
        if ((votingResult.length === offerCount) && (distinctOfferLetter.length === offerCount)) {
          setNextButtonStatus(false);
        }
      }
    }
  };

  React.useEffect(() => {
    if (activeStep === steps.indexOf('COVER LETTER')) {
      setNextButtonStatus(false);
    }
    else if (activeStep === steps.indexOf('EXIT OFFER RETURNS')) {
      setNextButtonStatus(false);
    }
    else if (activeStep === steps.indexOf('EXIT BALLOT')) {
      setNextButtonStatus(true);
      exitBallotNextCheck();
    }
    else if (activeStep === steps.indexOf('KYC')) {
      setNextButtonStatus(true);
    }
    else if (activeStep === steps.indexOf('NFC')) {
      setNextButtonStatus(true);
    }
  }, [activeStep, steps]);

  React.useEffect(() => {
    if (activeStep === steps.indexOf('NFC')) { //NFC
      if (votingDetails.usTaxRes === 'US resident') {
        if (votingDetails.usResState !== '-') {
          setNextButtonStatus(false);
        }
        else {
          setNextButtonStatus(true);
          completedSteps.delete(activeStep);
        }
      }
      else {
        setNextButtonStatus(false);
      }
    }

    if (activeStep === steps.indexOf('KYC')) { //KYC
      if (votingDetails.usTaxRes !== '-') {
        setNextButtonStatus(false);
        if (votingDetails.usTaxRes === 'Not US resident') {
          completedSteps.delete(activeStep + 1);
        }
      }
      else {
        completedSteps.delete(activeStep);
        completedSteps.delete(activeStep + 1);
        setNextButtonStatus(true);
      }
    }
  }, [votingDetails, activeStep]);

  return (
    <>
      <div className='exit-voting-stepper'>
        <Box sx={{ width: '1446px' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (index === steps.indexOf('NFC')) {
                if (votingDetails.usTaxRes === 'Not US resident') {
                  stepProps.completed = false;
                }
              }

              if (completedSteps.has(index) && activeStep !== index ) {
                stepProps.completed = true;
              }


              return (
                <Step key={label} {...stepProps} onClick={() => handleStep(index)}>
                  <StepLabel {...labelProps} StepIconComponent={index === activeStep ? CustomStepIcon : null}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {//Summary Tab
            activeStep === steps.length ? (
              <>
                {
                  steps.length > 0 ? (<>
                    <React.Fragment>
                      <div className='stepper-content'>
                        <ExitSummary VotingDetails={votingDetails} VotingResult={votingResult} />
                      </div>
                    </React.Fragment>
                  </>) : <Loading />
                }
              </>
            ) : (
              <React.Fragment>
                <div className='stepper-content'>
                  {//Cover letter Tab
                    activeStep === steps.indexOf('COVER LETTER') ? (<>
                      <CoverLetter
                        Template={exitTemplate}
                        FAQUrl={exitProcessHeader.faqurl}
                      />
                    </>) : ""
                  }
                  {//Exit offer return Tab
                    activeStep === steps.indexOf('EXIT OFFER RETURNS') ? (<>
                      <ExitOfferReturns Template={exitTemplate} />
                    </>) : ""
                  }
                  {//Exit Ballot Tab
                    activeStep === steps.indexOf('EXIT BALLOT') ? (<>
                      <ExitBallot
                        Template={exitTemplate}
                        VotingResult={votingResult}
                        handleExitBallotSave={handleExitBallot}
                        handleOfferLetterSave={handleOfferLetter}
                      />
                    </>) : ""
                  }
                  {//KYC Tab
                    activeStep === steps.indexOf('KYC') ? (<>
                      <KYC
                        Template={exitTemplate}
                        VotingDetails={votingDetails}
                        handleUSResidentSave={handleUSResident} />
                    </>) : ""
                  }

                  {//NFC Tab
                    activeStep === steps.indexOf('NFC') ? (<>
                      <NFC
                        Template={exitTemplate}
                        VotingDetails={votingDetails}
                        handleChangeStateSave={handleChangeState}
                        handleChangeUSCitizenSave={handleChangeUSCitizen}
                      />
                    </>) : ""}
                </div>
                <div className='stepper-footer'>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      id='back-button'
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext} disabled={nextButtonStatus} id='next-button'>
                      {
                        votingDetails.usTaxRes === 'Not US resident' ? (<>
                          {activeStep === steps.length - 2 ? 'Review and sign' : 'Next'}
                        </>) : (<>
                          {activeStep === steps.length - 1 ? 'Review and sign' : 'Next'}
                        </>)
                      }
                    </Button>
                  </Box>
                </div>
              </React.Fragment>
            )}
        </Box>
      </div>
    </>
  );
}
