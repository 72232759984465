import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { post, get, put, upload } from '../../util/request';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { userInfo, updateUserInfoFun } from '../../redux/slice';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import Updata from '../../components/page/updata/index';
import './index.scss';
import forge from 'node-forge';
import { Form, message, Switch } from 'antd';
import phoneHeaderOption from '../../components/dialCode.json';
import { useTranslation } from 'react-i18next';
import { REGEX } from '../../constants';
import SubmitSuccessPopUp from '../../components/page/submitSuccessPopUp';
import Loading from '../../components/common/loading';
const FormItem = Form.Item;
const Profile = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const user = useSelector(userInfo);
  const formRef = useRef();
  const resetFormRef = useRef();
  const [isButton, setButton] = useState(true);
  const [detailData, setDetailData] = useState({
    clientId: null,
  });

  const [isSearchable, setIsSearchable] = useState(false);
  const [citiZenOption, setCitiZenOption] = useState([]);
  const [residencyOption, setResidencyOption] = useState([]);
  const [provinceOption, setProvinceOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [country, setCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false);;
  const CustomInput = ({ value, onChange, ref }) => {
    return (
      <>
        <input
          className="form-control"
          sytle={{ width: '100%' }}
          maxLength={50}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          ref={ref}
        />
      </>
    )
  }


  const CustomInputPwd = ({ value, onChange }) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const inputType = showPassword ? 'text' : 'password';
    return (

      <div className='password-input'>
        <input
          className="form-control"
          sytle={{ width: '100%' }}
          maxLength={20}
          placeholder={t('Enter new password')}
          value={value}
          type={inputType}
          autocomplete={"off"}
          onChange={(e) => onChange(e.target.value)}
        />
        <button className="change-btn f-c-title" onClick={togglePasswordVisibility}>
          <i className={`${showPassword ? 'icon-eye' : 'icon-close-eye'} eye`}></i>
        </button>
      </div >
    )
  }
  const [error, setError] = useState('');
  const CustomPhoneNumber = ({ value, onChange, inputRequired, maxLength }) => {
    let option = [];
    let header = value ? value.split(' ')[0] : '';
    let last = value ? value.split(' ')[1] : '';
    // setPhoneNumber(last)
    phoneHeaderOption.map((item) => {
      option.push({ value: item.dial, label: item.dial + '(' + item.en + ')' });
      if (header == item.dial) {
        header = { value: item.dial, label: item.dial + '(' + item.en + ')' };
      }
    });
    const validateInput = (val) => {
      if (!val.trim()) {
        setError('this field is required');
      } else {
        setError('');
      }
    };
    const handleChange = (e) => {
      onChange(header.value + ' ' + e.target.value);
      last = e.target.value;
      if (inputRequired) {
        validateInput(e.target.value);
      }
      // onChange(e.target.value);
      // setPhoneNumber(e.target.value);
    };
    const phoneCustomStyles = {
      control: (base) => ({
        ...base,
        height: 40,
        width: '40%',
        float: 'left',
      }),
    };
    return (
      <div className="select-box">
        <Select
          value={header}
          onChange={(e) => {
            onChange(e.value + ' ' + last);
            header = e;
          }}
          isSearchable={true}
          options={option}
          styles={phoneCustomStyles}
          maxLength={15}
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              primary25: '#F5F7FA',
              primary: '#003448',
            },
            // styles: { phoneStyles }
          })}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <input
            className="form-control"
            style={{ float: 'left', marginLeft: '10px' }}
            value={last}
            // onChange={(e) => {
            //   onChange(header.value + ' ' + e.target.value);
            //   last = e.target.value;
            // }}
            onChange={handleChange}
            maxLength={maxLength}
          />
          {inputRequired && (error && <p className="error-message">{error}</p>)}
        </div>


      </div>
    );
  };
  const CustomSelect = ({ value, onChange, option, name }) => {
    let valueOption = value && value != '' ? { value: value, label: value } : null;
    if (name == 'country') {
      valueOption = {
        ...valueOption,
        label: option?.find((item) => item?.countryregionid == value)?.label || value
      }
    } else if (name == 'state') {
      valueOption = {
        ...valueOption,
        label: option?.find((item) => item?.stateId == value)?.province || value
      }
    }
    return (
      <Select
        value={valueOption}
        onChange={(e) => {
          if (name == 'country') {
            setCountry(e.countryregionid);
            formRef?.current?.setFieldValue('state', '');
          }
          onChange(e.value);
        }}
        isSearchable={true}
        options={option}
        styles={customStyles}
        maxLength={50}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: '#F5F7FA',
            primary: '#003448',
          },
          // styles: { phoneStyles }
        })}
      />
    );
  };
  const CustomSubmitButton = ({ form, onClick, text, disabled }) => {
    const handleClick = () => {
      formRef?.current
        ?.validateFields()
        .then(() => {
          if (!error) {
            submitProfile(formRef.current?.getFieldsValue());
          } else {
            const errorList = document.querySelectorAll(".error-message")
            errorList[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        })
        .catch((err) => {
          const errorList = document.querySelectorAll(".ant-form-item-has-error")
          errorList[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
          console.log('errro');
        });
    };

    return (
      <div
        className="btn-box update-password"
        style={{ display: 'flex', justifyContent: 'end', marginRight: '80px' }}
      >
        <button html-type='submit' type="button" disabled={disabled} onClick={handleClick} className={`${disabled ? 'disabled-btn' : ""} ApplyBtn btn btn-outline-next`}>
          {text}
        </button>
      </div>
    );
  };

  const CustomResetSubmitButton = ({ form, onClick, text }) => {
    const handleClick = () => {
      resetFormRef?.current
        ?.validateFields()
        .then(() => {
          submitChangePassword(resetFormRef.current?.getFieldsValue());
        })
        .catch(() => {
          console.log('errro');
        });
    };

    return (
      <div
        className="btn-box update-password"
        style={{ display: 'flex', justifyContent: 'end', marginRight: '80px' }}
      >
        <button type="button" onClick={handleClick} className="ApplyBtn btn btn-outline-next">
          {text}
        </button>
      </div>
    );
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      width: 300,
      cursor: 'pointer'
    }),
  };
  const [key, setkey] = useState('');

  const encode = (value) => {
    var pubencryptKey = key;
    let rsaPublicKey = forge.pki.publicKeyFromPem(
      `-----BEGIN PUBLIC KEY-----${pubencryptKey}-----END PUBLIC KEY-----`
    );
    let encrypted = rsaPublicKey.encrypt(value, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });
    return forge.util.encode64(encrypted);
  };

  const getkey = async () => {
    let deviceId = {
      deviceId: 'Web',
    };
    const data = await get('/auth/key', deviceId);
    if (!data) {
      return;
    }
    setkey(data.message);
  };
  const [isCheck, setisCheck] = useState(false);

  const checkOn = () => {
    setisCheck(true);
  };

  const checkOff = () => {
    setisCheck(false);
  };

  const init = async () => {
    let setting = await getSetting();
    let params = {
      offset: 'DESC',
      deviceId: null,
      deviceModel: ' WEB',
      deviceToken: null,
      entry: 'WEB',
      isChina: false,
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/profile', params);
    if (data) {
      var day = data.dob.slice(8, 10)
      var year = data.dob.slice(0, 4)
      var month = data.dob.slice(5, 7)
      data.dob = year + ' / ' + month + ' / ' + day
      let obj = {
        ...data,
        MARKET: setting.MARKET,
        CORPORATE: setting.CORPORATE,
      };
      formRef.current?.setFieldsValue(obj);
      setDetailData(obj);
      setCountry(data.country);
    }
  };

  const getCountryOption = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/profile/country', params);
    if (data) {
      if (data.length > 0) {
        data.map((item, index) => {
          data[index].value = item.countryregionid;
          data[index].label = item.name;
        });
        setCountryOption(data);
      }
    }
  };
  const getProvinceOption = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/profile/province', params);
    if (data) {
      if (data.length > 0) {
        data.map((item, index) => {
          data[index].value = item.stateId;
          data[index].label = item.province;
          data[index].show = true;
        });
        setProvinceOption(data);
      }
    }
  };
  const getCitiOption = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/profile/citizenship', params);
    if (data) {
      if (data.length > 0) {
        data.map((item, index) => {
          data[index].value = item.citizenship;
          data[index].label = item.citizenship;
        });
        setCitiZenOption(data);
      }
    }
  };
  useEffect(() => {
    init();
    getkey();
    getCountryOption();
    getProvinceOption();
    getCitiOption();
    getLangOption();
  }, []);
  const validatePassword = (rule, value) => {
    // 使用正则表达式验证邮箱地址
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]\D|.*\D[0-9])(?=.*[\W_]\w|.*\w[\W_]).{10,20}$/;
    if (!REGEX.password.test(value)) {
      return Promise.reject(t('PasswordRules'));
    }
    return Promise.resolve();
  };
  useEffect(() => {
    if (country) {
      let options = [...provinceOption];
      options.map((item) => {
        item.show = false;
        if (item.countryregionid == country) {
          item.show = true;
        }
      });
      setProvinceOption(options);
    }
  }, [country]);

  const submitProfile = async (detail) => {
    setLoading(true)
    const country = countryOption.find(item => item?.value == detail?.country)?.countryregionid || detail?.country;
    let params = {
      ...detail,
      country,
      accessToken: user.accessToken,
      userId: user.userId,
    };
    params.nationalIdFileId = detail.nationalId;
    params.passportFileId = detail.passport;
    const data = await post('/appUser/profile/request', params);
    if (data) {
      setLoading(false)
      setIsModalOpen(true);
    }
  };
  const getSetting = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/push/setting', params);
    if (data) {
      return data;
    }
  };
  const [langOption, setLangOption] = useState([])
  const getLangOption = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/option/primaryLanguage', params);
    if (data) {
      let option = [];
      data?.payload?.map((item) => {
        option.push({ value: item.code, label: item.name });
      });
      setLangOption(option);
    }
  };
  const updateSetting = async (name, value) => {
    let params = {
      onOff: value,
      type: name,
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await put('/appUser/push/setting', params);
    if (data) {
      return data;
    }
  };
  const submitChangePassword = async (detail) => {
    let params = {
      newPwd: encode(detail.newPwd),
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await put('/appUser/changePassword', params);
    if (data) {
      messageApi.open({
        type: 'success',
        content: 'Change Password Success ',
      });
    }
  };
  const customValidator = (_, value) => {
    if (!value) {
      const field1Value = formRef?.current?.getFieldValue('nationalId');
      const field2Value = formRef?.current?.getFieldValue('passport');
      if (!field1Value && !field2Value) {
        return Promise.reject(
          new Error('At least one of the passport and national ID is required.')
        );
      }
    }
    return Promise.resolve();
  };

  const ResetPassword = () => {
    return (
      <>
        <Form ref={resetFormRef}>
          <div className="headline">
            <p>{t('Change password')}</p>
          </div>

          <div className="tabsContent">
            <div className="address col-sm-6">
              <div className="list-box">
                <div className="list">
                  <p className='flex-1'>{t('New Password')}</p>
                  <FormItem
                    name="newPwd"
                    rules={[
                      { required: false, message: 'this field is required' },
                      { validator: validatePassword },
                    ]}
                    className='flex-1'
                  >
                    <CustomInputPwd />
                  </FormItem>
                </div>
                {/* <div className="list">
                  <p className="title"></p>
                  <p className="col-sm-6">
                    {t('*Minimum length of 10')}<br></br>
                    {t('*Must contain at least 2 uppercase, lowercase, numeric or special characters')}
                  </p>
                </div> */}
                <div className="list">
                  <p>{t('Confirm Password')}</p>
                  <FormItem
                    name="comPwd"
                    rules={[
                      { required: true, message: 'this field is required' },
                      {
                        validator(_, value) {
                          let newPwd = resetFormRef?.current.getFieldValue('newPwd');
                          if (!value || (value && value == newPwd)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Comfirm Passwords not same!'));
                        },
                      },
                    ]}
                  >
                    <CustomInputPwd />
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <CustomResetSubmitButton
          formRef={resetFormRef}
          onClick={() => submitChangePassword}
          text={t('Update')}
        />
      </>
    );
  };
  const SubmitSuccessPending = ({ text }) => {
    return (
      <div className="pending-box">
        <div className="left-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.7491 9.70957V9.00497C18.7491 5.13623 15.7274 2 12 2C8.27256 2 5.25087 5.13623 5.25087 9.00497V9.70957C5.25087 10.5552 5.00972 11.3818 4.5578 12.0854L3.45036 13.8095C2.43882 15.3843 3.21105 17.5249 4.97036 18.0229C9.57274 19.3257 14.4273 19.3257 19.0296 18.0229C20.789 17.5249 21.5612 15.3843 20.5496 13.8095L19.4422 12.0854C18.9903 11.3818 18.7491 10.5552 18.7491 9.70957Z" stroke="white" stroke-width="1.5" />
            <path d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19" stroke="white" stroke-width="1.5" stroke-linecap="round" />
          </svg>
        </div>
        <div className="right-text">{text}</div>
      </div>
    )
  }
  const handleOk = () => {
    setIsModalOpen(false);
    init();
  };
  return (
    <div className="profile">
      {loading ? <Loading /> : null}
      <SubmitSuccessPopUp isModalOpen={isModalOpen} title={t('submissionSuccessful')} content={t('submissionSuccessfulDescription')} handleOk={handleOk} handleCancel={handleOk} />
      {contextHolder}
      <div className="main-box">
        <div className="headline"><p>{t('Profile')}</p></div>
        <div className="statement-box">
          <div className="col-sm-6 list">
            <span>{user.sfUserType == 'CL' ? t('Client ID') : t('Vendor ID')}</span>
            <p>{detailData.clientId}</p>
          </div>
          <div className="col-sm-6 list">
            <span>{t('Username')}</span>
            <p>{detailData.username}</p>
          </div>
          {user.sfUserType == 'BIZ' ? (
            <div className="col-sm-6 list">
              <span>{t('Name')}</span>
              <p>{detailData.fullName}</p>
            </div>
          ) : (
            <></>
          )}
          {detailData.wigVendTypeId != 'IFA' ? (
            <>
              <div className="col-sm-6 list">
                <span>{t('First name')}</span>
                <p>{detailData.firstName}</p>
              </div>

              <div className="col-sm-6 list">
                <span>{t('Middle Name')}</span>
                <p>{detailData.middleName}</p>
              </div>
              <div className="col-sm-6 list">
                <span>{t('Last Name')}</span>
                <p>{detailData.lastName}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {user.sfUserType == 'CL' ? (
            <>
              <div className="col-sm-6 list">
                <span>{t('Date of Birth')}</span>
                <p>{detailData.dob}</p>
              </div>
              <div className="col-sm-6 list">
                <span>{t('ITIN')}</span>
                <p>{detailData.itin ? detailData.itin : 'N/A'}</p>
              </div>
              <div className="col-sm-6 list">
                <span>{t('ITN')}</span>
                <p>{detailData.itin ? detailData.itn : 'N/A'}</p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {isButton ? (
          <div>
            {
              user.sfUserType != 'INTERNAL' && <ResetPassword></ResetPassword>
            }
            {
              detailData.latestUpdatedStatus === 0 &&
              <SubmitSuccessPending text={t("SubmitProfilePending")}></SubmitSuccessPending>
            }
            <div className="headline">
              <p>{t('Edit Profile')}</p>
            </div>
            <div className="tabsContent">
              <Form autoComplete="off" ref={formRef} onFinish={submitProfile} scrollToFirstError>
                <div className="information">
                  {user.sfUserType != 'INTERNAL' && <p className="title">{t('General Information')}</p>}
                  <div className="list-box">
                    {
                      user.sfUserType != 'INTERNAL'
                        ? (
                          <div className="col-sm-6 list d-flex align-items-center justify-content-between">
                            <span>{t('Mobile Phone')}</span>
                            <div className="input-box">
                              <FormItem
                                name="phone"
                                rules={[{ required: true, message: 'this field is required' }]}
                              >
                                <CustomPhoneNumber inputRequired maxLength={15} />
                              </FormItem>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                    {(user.sfUserType != 'CL' &&
                      user.sfUserType != 'INTERNAL') &&
                      (detailData.wigVendTypeId == 'Planner' ||
                        detailData.wigVendTypeId == 'Broker' || user.sfUserType == 'ADVISOR' || detailData.wigVendTypeId == 'SubRefComp'
                        || detailData.wigVendTypeId == 'IFA' || detailData.wigVendTypeId == 'SELLER') ? (
                      <div className="col-sm-6 list d-flex align-items-center justify-content-between">
                        <span>{t('Business Phone')}</span>
                        <div className="input-box d-flex">
                          <FormItem
                            name="businessPhone"
                            rules={[{ required: false, message: 'this field is required' }]}
                          >
                            <CustomPhoneNumber maxLength={15} />
                          </FormItem>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {user.sfUserType == 'CL' ? (
                      <div className="col-sm-6  list d-flex align-items-center justify-content-between">
                        <span>{t('Citizenship')}</span>
                        <div className="input-box d-flex">
                          <div className="select-phone">
                            <FormItem
                              name="citizenship"
                              rules={[{ required: false, message: 'this field is required' }]}
                            >
                              <CustomSelect option={citiZenOption} />
                            </FormItem>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {
                      user.sfUserType != 'INTERNAL' &&
                      <div className="col-sm-6 list d-flex align-items-center justify-content-between">
                        <span>{t('Email Address')}</span>
                        <div className="input-box d-flex">
                          <FormItem
                            name="email"
                            // label="email"
                            rules={[{ required: true, message: 'this field is required' }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                      </div>
                    }
                    {user.sfUserType == 'CL' ? (
                      <>
                        <div className="col-sm-6 list d-flex align-items-center justify-content-between">
                          <span>{t('Residency')}</span>
                          <div className="input-box d-flex">
                            <div className="select-phone">
                              <FormItem
                                name="residencyID"
                                rules={[{ required: false, message: 'this field is required' }]}
                              >
                                <CustomSelect option={citiZenOption} />
                              </FormItem>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 list d-flex align-items-center justify-content-between">
                          <span>{t("Language")}</span>
                          <div className="input-box d-flex">
                            <div className="select-phone">
                              <FormItem
                                name="primaryLanguage"
                                rules={[{ required: true, message: 'this field is required' }]}
                              >
                                <CustomSelect option={langOption} />
                              </FormItem>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {user.sfUserType == 'CL' ? (
                  <div>
                    <div className="divider"></div>
                    <div className="address col-sm-6">
                      <p className="title">{t('Address')}</p>
                      <div className="hint d-flex">
                        <div className="svg-box">
                          <svg
                            className="svg-circle"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 20C4.48674 20 0 15.5054 0 9.97817C0 4.45098 4.48674 0 10 0C15.5133 0 20 4.47756 20 9.97817C20 15.4788 15.5133 20 10 20ZM10 1.84493C5.50189 1.84493 1.84091 5.49302 1.84091 9.97817C1.84091 14.4633 5.50189 18.1551 10 18.1551C14.4981 18.1551 18.1591 14.488 18.1591 9.97817C18.1591 5.46835 14.5 1.84493 10 1.84493Z"
                              fill="#003448"
                            />
                          </svg>
                          <svg
                            className="svg-exclamation"
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="10"
                            viewBox="0 0 2 10"
                            fill="none"
                          >
                            <path
                              d="M0.918557 9.50633C0.412028 9.50633 0 9.09255 0 8.58387V4.72129C0 4.21261 0.412028 3.79883 0.918557 3.79883C1.42509 3.79883 1.83711 4.21261 1.83711 4.72129V8.58387C1.83711 9.09255 1.42509 9.50633 0.918557 9.50633Z"
                              fill="#003448"
                            />
                            <path
                              d="M0.918557 2.89808C0.412028 2.89808 0 2.4843 0 1.97561V1.41758C0 0.908897 0.412028 0.495117 0.918557 0.495117C1.42509 0.495117 1.83711 0.908897 1.83711 1.41758V1.97561C1.83711 2.4843 1.42509 2.89808 0.918557 2.89808Z"
                              fill="#003448"
                            />
                          </svg>
                        </div>
                        <p>{t('Our team may contact you to verify and update your correspondance address')}</p>
                      </div>
                      <div className="list-box">
                        <div className="list">
                          <p>{t('Correspondence Address')}</p>
                          <FormItem
                            name="addressLine1"
                            rules={[{ required: true, message: 'this field is required' }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                        <div className="list">
                          <p></p>
                          <FormItem
                            name="addressLine2"
                            rules={[{ required: false, message: 'this field is required' }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                        <div className="list">
                          <p></p>
                          <FormItem
                            name="addressLine3"
                            rules={[{ required: false, message: 'this field is required' }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                        <div className="list">
                          <p>{t('City')}</p>
                          <FormItem
                            name="city"
                            rules={[{ required: false, message: 'this field is required' }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                        <div className="list">
                          <p>{t('Country')}</p>
                          <div className="select-normal">
                            <FormItem
                              name="country"
                              rules={[{ required: true, message: 'this field is required' }]}
                            >
                              <CustomSelect option={countryOption} name={'country'} />
                            </FormItem>
                          </div>
                        </div>
                        <div className="list">
                          <p>{t('Province')}</p>
                          <div className="select-normal">
                            <FormItem
                              name="state"
                              rules={[{ required: false, message: 'this field is required' }]}
                            >
                              <CustomSelect
                                name={'state'}
                                option={provinceOption.filter((item) => item.show == true)}
                              />
                            </FormItem>
                          </div>
                        </div>
                        <div className="list">
                          <p>{t('Postal Code')}</p>
                          <FormItem
                            name="zipCode"
                            rules={[{ required: false, message: 'this field is required' }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {user.sfUserType == 'CL' ? (
                  <div>
                    <div className="divider"></div>

                    <div className="identification col-sm-6">
                      <p className="title">{t('Identification')}</p>
                      <div className="list-box">
                        <div className="list">
                          <div className="check-box">
                            <div className="check">
                              <p>{t('Passport')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <FormItem
                        name="passport"
                        rules={[{ required: false }]}
                      >
                        <Updata type={'USER_PASSPORT'} formRef={formRef} />
                      </FormItem>
                      <div className="list-box">
                        <div className="list">
                          <div className="check-box">
                            <div className="check">
                              <p>{t('National ID')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <FormItem
                        name="nationalId"
                        rules={[{ required: false }]}
                      >
                        <Updata type={'USER_NATIONAL_ID'} formRef={formRef} />
                      </FormItem>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {
                  user.sfUserType != 'INTERNAL' && <div className="divider"></div>
                }
                <div className="list-box row">
                  <p className="title" style={{ fontSize: '18px' }}>
                    {t('Setting')}
                  </p>
                  <div className="col-sm-6 list d-flex setting-content">
                    <span>{t('Market Updates')} </span>
                    <div className="input-box d-flex" style={{ marginLeft: '20px' }}>
                      <FormItem
                        name="MARKET"
                        rules={[{ required: false, message: 'this field is required' }]}
                      >
                        <Switch
                          onChange={(checked, event) => {
                            updateSetting('MARKET', checked);
                          }}
                        ></Switch>
                      </FormItem>
                    </div>
                  </div>
                  <div className="col-sm-6 list d-flex setting-content">
                    <span>{t('Corporate Updates')}</span>
                    <div className="input-box d-flex" style={{ marginLeft: '20px' }}>
                      <div className="select-phone">
                        <FormItem
                          name="CORPORATE"
                          rules={[{ required: false, message: 'this field is required' }]}
                        >
                          <Switch
                            onChange={(checked, event) => {
                              updateSetting('CORPORATE', checked);
                            }}
                          ></Switch>
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

              </Form>
            </div>
            <CustomSubmitButton
              formRef={formRef}
              onClick={() => submitProfile}
              text={t('Submit')}
              disabled={detailData.latestUpdatedStatus === 0 ? true : false}
            />
          </div>
        ) : (
          <div className="tabsContent"></div>
        )}
      </div>
    </div>
  );
};
export default Profile;
