import './index.scss'
import { useHistory } from 'react-router';
import { useState, useEffect } from 'react';
import ExitVotingTemplate from './exitVotingTemplate/index.js';
import ExitVotingStepper from './exitVotingStepper/index.js';

export default ExitVoting; function ExitVoting() {
    const history = useHistory();
    const [recId, setRecId] = useState('');
    useEffect(() => {
        if (history.location.state !== undefined) {
            setRecId(history.location.state.recId);
        }
        else {
            setRecId(sessionStorage.getItem('recId'));
        }
    }, []);
    return (
        recId !== '' ? (<>
            <div className='exit-voting'>
                {
                    <>
                        {/* <ExitVotingTemplate RecId={recId} /> */}
                        <ExitVotingStepper RecId={recId} />
                    </>
                }

            </div>
        </>) : ""
    )
}
